.head{
    width: 100vw;
    height: 5em;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    top: 0em;
    font-family: 'Candara', sans-serif;
    background-color: #253C78;
    z-index: 2;

}
.headplus{
    width: 100vw;
    height: 9em;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 5em;
    font-family: 'Candara', sans-serif;

    /*******************************/
    z-index: 2;


}

.headerspace{
    /*width: 100vw;*/
    width: 1em;
    height: 5em;
}
.headerspace2{
    width: 100vw;
    height: 13em;
}

.btnheader{
    margin-left: 0.5em;
    margin-right: 0.5em;
    border-radius: 0.3em;
    text-decoration: none;
    font-size: 0.7em;
    padding: 0.7em;
    /*border: solid 1px #53917E;*/
    border: solid 1px #023360;
    min-width: 9em;
    transition: 0.3s;
}

.btnheader:hover{
    padding-left: 1.3em;
    padding-right: 1.3em;
    color: white;
    /*
    background-color:#2c8bdd;
    border: solid 1px #2c8bdd;
    */
    background-color:#2c8bdd;
    border: solid 1px #2c8bdd;
}

.btnheader0{
    margin-left: 0.5em;
    margin-right: 0.5em;
    border-radius: 0.3em;
    text-decoration: none;
    font-size: 0.7em;
    padding: 0.7em;
    /*border: solid 1px #53917E;*/
    border: solid 1px #023360;
    min-width: 9em;
    transition: 0.3s;
}

.btnheader0:hover{
    padding-left: 1.3em;
    padding-right: 1.3em;
    color: white;
    /*
    background-color:#2c8bdd;
    border: solid 1px #2c8bdd;
    */
    background-color:#2c8bdd;
    border: solid 1px #2c8bdd;
}

.btnheader1{
    background-color: transparent;
    /*
    color:#53917E;
    */
    color:white;
    border: solid 1px white;
    margin-top: 1em;
    margin-bottom: 0.3em;
}
.btnheader1:hover{
    padding-left: 2em;
    padding-right: 2em;
}
.btnheader2{
    /*
    background-color:#53917E;
    */
    background-color:#023360;
    color: white;
    margin-bottom: 1em;
    margin-top: 0.3em;
}
.head2button{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 9em;
    width: 40vw;
}
.btnheader3{
    background-color: #023360;
    color:white;
    margin-top: 1em;
    margin-bottom: 0.3em;
}
.btnheader3:hover{
    padding-left: 2em;
    padding-right: 2em;
}
.btnheader4{
    background-color: transparent;
    border: solid 1px white;
    color: white;
    margin-bottom: 1em;
    margin-top: 0.3em;
}
.head2button2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*min-width: 9em;*/
    min-width: 8em;

}

.logohead{
    width: 4em;
    transform: scale(0.85);
    transition: 0.3s;
}
.logohead:hover{
    transform: scale(1);
}
.logolink{
    width: 4em;
}
/*
.logohead:hover + .logohead>g {
    fill: red!important;
    display: none;
}*/

/* SVG */
.logopathenter{
    fill: #2c8bdd !important;
}
.logopathenter2{
    fill: #62a893 !important;
    transition: 0.3s;
}
.cls-1{
    transition: 0.3s;
}
.hamenter{
    stroke: #2c8bdd !important;
}
.cls-2{
    transition: 0.3s;
}
.cls-3{
    transition: 0.3s;
}
.cls-5{
    transition: 0.3s;
}
/* ...... */

.hammenu{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    color: white;
    transition: 0.3s;
    width: 4em;
}
.hammenu:hover{
    color: #2c8bdd;
}
.hamlogo{
    width: 2em;
    margin-right: 0.5em;  
    transform: scale(0.85);
    transition: 0.3s;
}
.hamlogo:hover{
    transform: scale(1);
}


.boxco{
    max-width: 90vw;
    max-height: 90vh;
    /*height: 35em;*/
    width: 25em;
    /*background-color: white;*/
    background-color: #253C78;
    border-radius: 0.5em;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.boxco02{
    max-width: 90vw;
    /*max-height: 90vh;*/
    max-height: max(90vh, 25em);
    /*height: 35em;*/
    width: 25em;
    /*background-color: white;*/
    background-color: #253C78;
    border-radius: 0.5em;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.frangeclose{
    width: 100%;
    height: 2.5em;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 1em;

}
.closesvg{
    width: 1em;
    margin: 1em;
    transform: scale(0.9);
    transition:0.3s
}
.closesvg:hover{
    transform: scale(1);
}

.formboxco{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.inp{
    max-width: 90%;
    width: 15em;
    margin-bottom: 1em;
    margin-left: 0.5em;
    margin-right: 0.5em;
    border-radius: 0.5em;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    border: none;
    outline: none;
    font-size: 0.8em;
    height: 0.8em;
    padding: 1.2em;
    background-color: #3c75a7;
    color: white;
    opacity: 0.8;
    transition:0.3s;
}
.inp::placeholder {
    color: #f1f1f1;
  }
.inp:hover{
    opacity: 1;
}

.inp:valid:not(:placeholder-shown){
    background-color: #62a893;
}

.inpin:invalid:not(:placeholder-shown){
    background-color: #EE8282;
}
.btnboxco1{
    margin: 0.8em;
    font-size:0.8em;
    padding: 0.8em;
    background-color:#0f4b83;
    color: white;
    margin: 0.8em;
    border-radius: 0.3em;
    text-decoration: none;
    border: solid 1px #0f4b83;
    transition: 0.3s;
}

.btnboxco1:hover{
    padding-left: 1.2em;
    padding-right: 1.2em;
    color: white;
    background-color:#2c8bdd;
    border: solid 1px #2c8bdd;
}

.btnboxco2{
    margin: 0.8em;
    font-size:0.8em;
    padding: 0.8em;
    background-color:transparent;
    color: white;
    margin: 0.8em;
    margin-bottom: 3em;
    border-radius: 0.3em;
    text-decoration: none;
    border: solid 1px white;
    transition: 0.3s;
    
}

.btnboxco2:hover{
    padding-left: 1.2em;
    padding-right: 1.2em;
    color: white;
    background-color:#2c8bdd;
    border: solid 1px #2c8bdd;
}

.textboxco{
    max-width: 90%;
}
.titleboxco{
    /*font-family: 'Pirata One', cursive;*/
    font-family: 'Merriweather', cursive;
    font-weight: 500;
}
.checkbox{
    width: 4em;
}
.roundCheck{
    width: 3em;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.boxsuccess{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2em;
    margin-top: 1em;
}
.account{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 9em;
}
.iconaccount{
    width: 1.2em;
    margin-right: -0.5em;
}
.errorm{
    text-align: center;
    color: #EE8282;
    max-width: 90%;
    width: 15em;
    margin-bottom: 1em;
    margin-left: 0.5em;
    margin-right: 0.5em;
    font-size: 0.8em;
}
.goodm{
    text-align: center;
    color: #BDEE82B5;
    max-width: 90%;
    width: 15em;
    margin-bottom: 1em;
    margin-left: 0.5em;
    margin-right: 0.5em;
    font-size: 0.8em;
}

.navhead2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    min-width: 24em;
    max-width: 100vw;
    width: 40vw;
    overflow: hidden;
    border-bottom-left-radius: 0.2em;
    border-bottom-right-radius: 0.2em;
    background-color: #433A3F;
    /*background-color: #a37352;*/
}

.linkhead2{
    text-decoration: none;
    font-size: 1em;
    color: white;
    /*
    color: #a37352;*/
    margin: 0.5em;
    transition: 0.3s;
    text-align: center;
}
.linkhead2:hover{
    color: #2c8bdd;
}



@media screen and (min-width: 768px) {


    .headerspace2{
        width: 100vw;
        height: 5em;
    }

    .head2button{
        display: flex;
        flex-direction:row;
        justify-content: center;
        align-items: center;
        min-width: 22em;
        width: auto;
    }
    .btnheader{
        margin: 0.9em;
        font-size:0.9em;
        padding: 0.9em;
    }

    .navhead{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        min-width: 24em;
        width: 40vw;
    }

    .linkhead{
        text-decoration: none;
        font-size: 1em;
        color: white;
        margin: 0.5em;
        transition: 0.3s;
        text-align: center;
    }
    .linkhead:hover{
        color: #2c8bdd;
    }

    .logolink{
        width: 2em;
    }

}



@keyframes openmenu {
  
    from {
      height: 0em;
      opacity: 0.2;
    }
    to {
        height: 9em;
        opacity: 1;
    }
}

@keyframes openmenu2 {
  
    from {
      height: 5em;
      opacity: 0.2;
    }
    to {
        height: 13em;
        opacity: 1;
    }
}

@keyframes closemenu {
  
    from {
      height: 9em;
      opacity: 1;
    }
    to {
        height: 0em;
        opacity: 0;
    }
}


@keyframes closemenu2 {
  
    from {
      height: 13em;
      opacity: 1;
    }
    to {
        height: 5em;
        opacity: 0;
    }
}


@media (prefers-reduced-motion: no-preference) {
    .menuopen {
      animation: openmenu 0.5s ease-in-out forwards;
    }
    .menuclose {
        animation: closemenu 0.5s ease-in-out forwards;
    }

    .menuopen2 {
        animation: openmenu2 0.5s ease-in-out;
    }
    .menuclose2 {
          animation: closemenu2 0.5s ease-in-out;
    }

  }