p{
  max-width: 85vw;
}
label{
  max-width: 85vw;
}

.boxlist{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .boxl{
    background-color: #064785;
    padding: 1em;
    margin: 1em;
    border-radius: 0.5em;
    width: 8.5em;
    min-height: 28em;
  }
.boxgroup2{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.boxgroup4{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.firstaddgame {
  margin: 1.5em;
  margin-bottom: 3em;
}
.inpaddgame{
  margin-top: 0.2em;
  margin-bottom: 1em;
}
.surantinpaddgame{
  margin-top: -0.5em;
  margin-bottom: 1em;
}

.radioperso{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

/* Masquer le bouton radio original */
.hidden-radio {
  display: none;
}

/* Style du conteneur du bouton radio */
.custom-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  margin-bottom: 12px;
  user-select: none;
}

/* Style du faux bouton radio */
.radiomark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 2px solid #555;
  border-radius: 50%;
  transform: scale(0.5);
  transition: 0.2s;
}

/* Au survol */
.custom-radio:hover .radiomark {
  /*background-color: #ddd;*/
  background-color: #7ec3ff;
}

/* Quand le bouton radio est sélectionné */
.custom-radio input:checked ~ .radiomark {
  background-color: #2196F3;
  border-color: #2196F3;
}

/* Style de l'indicateur de sélection à l'intérieur du bouton radio */
.radiomark:after {
  content: "";
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  background: white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition:0.3s
}

.custom-radio input:checked ~ .radiomark:after {
  display: block;
}

/* Masquer la checkbox originale */
.hidden-checkbox {
  display: none;
}

/* Style du conteneur de la checkbox */
.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
}

/* Style de la fausse checkbox */
.checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 2px solid #555;
  border-radius: 4px;
  transform: scale(0.5);
}

/* Au survol */
.custom-checkbox:hover .checkmark {
  background-color: #ddd;
}

/* Quand la checkbox est cochée */
.custom-checkbox input:checked ~ .checkmark {
  background-color: #2196F3;
  border-color: #2196F3;
}

/* Style de la coche de la checkbox */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.par1{
  /*
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: flex-start;
  */
  display: none;
}
.par2{
  /*
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  */
  display: none;
}

@media screen and (min-width: 768px) {
  .par0{
    /*min-width: 26em;*/
    /*min-width: 50em;*/
    min-width: min(50em, 100vw);
  }
    .boxgroup2{
        display: flex;
        flex-direction:row;
        justify-content: flex-start;
        align-items: center;
    }
}
  
  
  @media screen and (min-width: 1024px) {

    .boxgroup4{
        display: flex;
        flex-direction:row;
        justify-content: flex-start;
        align-items: center;
    }
}

@media screen and (min-width: 1400px) {
  .par1{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }
  .par2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}