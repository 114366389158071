.exit{
    display: none!important;
}

.bigbox{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Crimson Text', serif;
    /*color:#827F78;*/
    color:#ccc9c3;
    font-size: 18px;
    line-height: 1.5;
}
.bigboxg{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*font-family: 'Crimson Text', serif;*/
    /*color:#827F78;*/
    color:#ccc9c3;
    font-size: 18px;
    line-height: 1.5;
    min-height: 100vh;
    background-color: #253C78;
}
.biggame{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*font-family: 'Crimson Text', serif;*/
    /*color:#827F78;*/
    color:#ccc9c3;
    font-size: 18px;
    line-height: 1.5;
    background-color: #253C78;
    min-height: 100vh;
    /*position: absolute;
    top: 0em;
    z-index: 3;*/
}
.lignetype1{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 90vw;
    /*padding-right: 10em;
    padding-left: 10em;*/
    max-width: 85vw;

}

.lignetype2{
    display: flex;
    flex-direction: column;
    align-items: center;
    /*max-width: 90vw;
    padding-right: 10em;
    padding-left: 10em;*/
    max-width: 85vw;

}


.titlepage{
    /*font-family: 'Pirata One', cursive;*/
    font-family: 'Merriweather', cursive;
    /*color: #362F2E;*/
    color: #f5f8ff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: xxx-large;
}
.titleaccueil{
    margin-top: 1em;
    margin-bottom: 0.3em;
}
.logoa{
    width: 0.6em;
    margin: 0.1em;
    margin-bottom: 0.6em;
}
.imgmosque{
   /* max-width: 90vw;
    width: 45em;*/
    max-width: 70vw;
    min-width: 15em;
    margin-bottom:-2.5em;
    margin-top: 0.5em;
    width: 70vw;
}

.imgyoung{
    width: 100%;
}
.imgroom{
    width: 100%;
    margin: 1em;
    border-radius: 0.2em;
    max-height: 20em;
    max-width: 20em;
}
.boxdemiimage{
    overflow: hidden;
    width: 40vw;
    max-height: 20em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1vw;
}
.imgroomsmall{
    width: 100%;
    border-radius: 0.2em;
}
.imgoldsmall{
     width: 100%;
 }

.btngame{
    margin: 0.9em;
    font-size:0.9em;
    padding: 0.9em;
}
.btngame{
    margin: 0.9em;
    font-size:0.9em;
    padding: 0.9em;
    border-radius: 0.3em;
    text-decoration: none;
    background-color:#0f4b83;
    color: white;
    border: solid 1px #0f4b83;
    /*min-width: 7.5em;*/
    transition: 0.3s;
}

.btngame:hover{
    padding-left: 1.3em;
    padding-right: 1.3em;
    color: white;
    background-color:#2c8bdd;
    border: solid 1px #2c8bdd;
}

.notop{
    margin-top: 0em;
}

.allligne{
    width: 100%;
    padding: 0em;
    display: flex;
    align-items: center;
    justify-content: center;
}
.titrescnd{
    margin: 0em;
    margin-bottom: 0.3em;
    /*font-family: 'Pirata One', cursive;*/
    /*font-family: 'Candara', sans-serif;*/
    font-family: 'Merriweather', cursive;
    color: #f5f8ff;
    font-size: xx-large;

}
.circleforsvg{
    border: solid #433A3F 6px;
    padding: 2.3em;
    border-radius: 50%;
    margin: 2em;
}

.lignebase{
    width: 90vw;
    margin-left: 10em;
    margin-right: 10em;
}
.lignefull{
    width: 100%;
}
.lignepp{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2em;
}

.imgmachine{
    width: 25vw;
    margin-top: 0em;
    /*max-width: 26em;*/
    margin: 2em;
    max-width: 14em;
}

.boximgp{
    overflow: hidden;
    width: 100%;
    max-width: 40em;
    display: flex;
    max-height: 40em;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.imgparchemin{
    transform: rotate(90deg);
    position: relative;
    top: -6em;
    width: 28em;
}

.boxform{
    position: relative;
    top: 1em;
    height: 0em;
}

/*
@-moz-document url-prefix() {
    .imgparchemin{
        transform: rotate(90deg);
        position: relative;
        top: -6em;
        width: auto;
    }
    .boximgp{
        overflow: hidden;
        width: 100%;
        max-height: 40em;
        max-width: none;
        display: flex;
        align-items: normal ;
        justify-content: center;
        flex-direction: row;
    
    }
    .boxform{
        position: relative;
        top: -33em;
        height: 0em;
    }
}
*/


.inp2{
    max-width: 90%;
    width: 15em;
    margin-bottom: 1em;
    margin-left: 0.5em;
    margin-right: 0.5em;
    border-radius: 0.5em;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    border: none;
    outline: none;
    font-size: 0.8em;
    height: 0.8em;
    padding: 1.2em;
    background-color: #3c75a7;
    color: white;
    opacity: 0.8;
    transition:0.3s;
    font-family: 'Candara', sans-serif;
}
.inp2::placeholder {
    color: #f1f1f1;
  }
.inp2:hover{
    opacity: 1;
}

.inp2:valid{
    background-color: #62a893;
}

.inp2in:invalid{
    background-color: #EE8282;
}
.inp2area{
    height: 8em;
    font-family: 'Candara', sans-serif;
    max-height: 8em;
    min-width: 15em;
    max-width: 15em;
}
.arrow{
    width: 1.5em;
    transition: 0.3s;
    transform: scale(0.9);
    margin: 1em;
    background-color: transparent;
}
.arrow:hover{
    transform: scale(1);
}
.arrow2{
    width: 1.5em;
    transition: 0.3s;
    transform: scale(0.9)rotate(180deg);
    margin: 1em;
}
.arrow2:hover{
    transform: scale(1)rotate(180deg);
}
.gamefrange1{
    width: 100%;
    display: flex;
}
.gamefrange{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.fondsalle{
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    position: fixed;
    top: 0;
    /*width: 100vw;*/
}

.imgfond{
    min-height: 100vh;
    min-width: 100vw;
    filter: blur(4px);
    /*-webkit-transform: scale(1.7);*/
    /*transform: scale(1.7);*/
}
.imgperso{
    max-width: 20em;
    max-height: 30em;
    width: 45vw;
}

.Circlebutton{
    /*background-color: #A37352;*/
    width: 15vw;
    height: 15vw;
    border-radius: 50%;
    border: 0.5em solid #A37352;
    backdrop-filter: brightness(200%) blur(35px)contrast(0.6)opacity(0.8);
    /*opacity: 0.24*/;
    background-clip: padding-box;
    /*transform: translateY(-3em)scale(0.9);*/
    /*max-width: 10em;
    max-height: 10em;
    */
    max-width: 6em;
    max-height: 6em;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Circlebutton:hover{
    /*transform: translateY(-3em)scale(1);*/
    background-color: #A37352;
    background-clip:border-box;
    backdrop-filter: none;
}
.CircleG{
    /*margin-left: 2vw;*/
    transform: translateY(-10vw)translateX(3vw)scale(0.9);
}
.CircleD{
    /*margin-right: 2vw;*/
    transform: translateY(-10vw)translateX(-3vw)scale(0.9);
}
.CircleG:hover{
    transform: translateY(-10vw)translateX(3vw)scale(1);
}
.CircleD:hover{
    transform: translateY(-10vw)translateX(-3vw)scale(1);
}

.icongame{
    width: 60%;
}

.boxdialog{
    /*
    width: 86vw;
    max-width: 42em;
    height: 15vw;
    max-height: 8em;
    */
    /*background-color: #EE8282;*/
    width: 90vw;
    max-width: 45em;
    position: relative;
    top: -10vw;
    /*transform: translateY(-10vw);*/
}
.imgdialog{
    width: 100%;
}
.dialog{
    width: 50vw;
    /*background-color: #EE8282;*/
    /*transform: translateY(-16.2vw);*/
    /*height: 12vw;*/
    /*transform: translateY(-12.4vw);*/
    height: 6vw;
    max-width: 25em;
    max-height: 6em;
    overflow: hidden;
    transform: translateY(-9.6vw);

}
.textdialog{
    white-space: pre-line;
    font-size: 1.96vw;
    width: 100%;
    margin: 0px;
    overflow: auto;
}

.gamefrange2{
    position: relative;
    /*top: -20vw;*/
    top: -26vw;
}
.actionbox{
    position: relative;
    /*top: -85vw;*/
    top: -78vw;
    /*height: 70vw;*/
    height: 0em;
    width: 55vw;
}

.linkwin{
    color: #62a893;
}
.linkloose{
    color: #EE8282;
}

.listlink{
    position: absolute;
    /*height: 70vw;*/
    height: 56vw;
    /*overflow: scroll;*/
    overflow: auto;
    width: 90%;
}

.linkgame{
    text-decoration: none;
    font-size: 3.5vw;
    color: #a37352;
    margin: 1vw;
    transition: 0.3s;
    text-align: center;
}
.linkgame:hover{
    color: #62a893;
}

.leaderbox{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0f4b83;
    border-radius: 0.5em;
    margin: 0.5em;
    /*padding: 2em;*/
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    padding-left: 1.5em;
    padding-right: 1.5em;
}
.numberleader{
    margin-right: 0.4em;
    font-size: xxx-large;
    margin-top: 0em;
    margin-bottom: 0em;
}
.choiceleader{
    margin-bottom: -1em !important;
}
.bestscore{
    /*color: #2c8bdd;*/
    color: #7ec3ff;;
    font-size: x-large;
    font-weight: bold;
}


#leader00{
    background-color: #b79d13bf;
}
#leader01{
    background-color: #4d4b4ba6;
}
#leader02{
    background-color: #82301d;
}

.boxp>p{
    margin: 0em;
}

.smallarrow{
    width: 1em;
    transform: rotate(-90deg);
}
.smallarrow2{
    width: 1em;
    transform: rotate(90deg);
}

.bt0{
    background-color: grey!important;
}

.presentation-section {
    font-family: 'Arial', sans-serif;
    /*padding: 30px;*/
    padding: 1em;
    /*background-color: #ffffff;*/
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 50px auto;
    text-align: center;
}

.presentation-section h1 {
    font-size: 24px;
    /*color: #00457C;*/
    color: #BADAED;
    margin-bottom: 20px;
}

.presentation-section p {
    font-size: 16px;
    margin-bottom: 20px;
    /*color: #333;*/
    line-height: 1.5;
}

.presentation-section ul {
    list-style-type: none;
    padding: 0;
}

.presentation-section li {
    font-size: 16px;
    margin-bottom: 10px;
    /*color: #BADCF7;*/
    color: #D4D4D4;
    line-height: 1.5;
    position: relative;
    padding-left: 25px;
    text-align: start;
}

.presentation-section li::before {
    content: '🔸';
    position: absolute;
    left: 0;
    top: 11px;
    transform: translateY(-50%);
    font-size: 20px;
    color: #007ACC;
}

.presentation-section li strong {
    /*color: #00457C;*/
    color: #BADAED;
}

.btng{
    margin-bottom: 5em;
}


.help{
  width: 20em;
  padding: 1em;
  margin: 1em;
  max-width: 78vw;
}
.pubh{
    max-width: 78vw;
}
.helpbox{
    width: 100%;
}

.enpar3{
    display: flex;
    justify-content: center;
}

.part{
    height: 55em;
    width: 33vw;
    margin: 1em;
}

#contact{
    height: 26em;
}

.boxverse{
    margin: 1em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 46em;
    /*max-width: 85vw;*/
    max-width: 75vw;
    padding: 2em;
    transition:0.3s;
    border-radius: 0.5em;
}
.boxverse:hover{
    background-color: #2c8bdd;
}
.thisverse{
    background-color: #0f4b83;
}

.barredubas {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #0f4b83;
    /* autres styles pour la position fixée */
    transition: none!important;
    z-index: 1;
    max-width: 50em;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}

.manetteplay{
    margin-top:1em;
}

.formbasm{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
    margin-bottom: 1em;
}
.formbasm>label{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5em;
}

@media screen and (min-width: 768px) {

    .manetteplay{
        margin-top:1.5em;
    }
    .lignebase{
        width: 90vw;
        margin-left: 10vw;
        margin-right: 10vw;
        margin-top:7em;
    }
    .bigbox{
        font-size: 21px;
    }
    .bigboxg{
        font-size: 21px;
    }
    .biggame{
        font-size: 21px;
    }

    .ligne1{

        padding-right: 10vw;
        padding-left: 10vw;
        height: 0em;
    }

    .lignetype2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        /*
        max-width: 90vw;
        padding-right: 10em;
        padding-left: 10em;
        */
        max-width: 85vw;

    }
    .imgyoung{
        min-width: 14em;
        align-self: flex-start;
        margin-top: 2em;
    }
    .imgroom{
        min-width: 14em;
        margin: 0em;
        margin-top: 1em;
    }
    .accueiltext{
        min-width: 20em
    }
    .parentprops {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 6px;
        grid-row-gap: 0px;
    }
        
        .div1props { grid-area: 1 / 1 / 2 / 2; }
        .div2props { grid-area: 1 / 2 / 2 / 3; }
        .div3props { grid-area: 2 / 1 / 3 / 2; }
        .div4props { grid-area: 2 / 2 / 3 / 3; }

    .boxparentpropos{
        /*min-height: 66em;*/
        min-height: calc(125em - 50vw);
    }
    .tocenteroom{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .torightroom{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 1em;
        margin-top: -0.7em;
    }

    .imgold2{
        max-width: 35vw;
    }
    .touproom{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        min-width: 18em;
    }

    .tobottomroom{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        min-width: 18em;
        padding-left: 1em;
    }
    .boximgold{
        overflow: hidden;
        margin-left: 0em;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }


    .titrescnd{
        margin: 0em;
        margin-bottom: 0.3em;
        /*font-family: 'Pirata One', cursive;*/
        /*font-family: 'Candara', sans-serif;*/
        font-family: 'Merriweather', cursive;
        color: #f5f8ff;
        font-size: xx-large;

    }
    .linkgame{
        font-size: 3vw;
    }
    .presentation-section {
        padding: 2em;
    }
}
@media screen and (min-width: 800px) {

    .actionbox{
        top: -30em;
        /*height: 27em;*/
        height: 0em;
        width: 21em;
    }
    .listlink{
        position: absolute;
        /*height: 70vw;*/
        height: 50vw;
        /*overflow: scroll;*/
        overflow: auto;
        width: 90%;
    }

}
@media screen and (min-width: 1024px) {
    .btext{
        min-width: 22em;
    }
    .imgmachine{
        width: 20vw;
        margin: 2em;
        max-width: 14em;
    }
    .lignepp{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: -12em;
    }
    .allligne{
        width: 100%;
        padding: 0em;
        justify-content: flex-start;
    }
    .bigbox{
        font-size: 20px;
    }
    .bigboxg{
        font-size: 20px;
    }
    .biggame{
        font-size: 20px;
    }
    .lignetype1{
        flex-direction: row-reverse;
    }
    .lignetype2{
        flex-direction: row;
    }
    .imgmosque {
        width: 50vw;
        min-width: auto;
        align-self: flex-start;
    }
    .imgyoung{

        width: 100%;
        margin-top: 0em;
        /* imgyoung2 */
        max-width: 28em;
    }
    .imgroom{
        width: 100%;
        margin-top: 0em;
        margin: 1em;
    }
    .textaccueil2{
        margin-top: -1em;
    }

.ligne1{

    padding-right: 10vw;
    padding-left: 10vw;
    height: 0em;
}

@media screen and (max-width: 1200px) {

    .ligne1{

        padding-right: 2vw;
        padding-left: 2vw;
        height: 0em;
    }
}
.boximgmosque{
    /*
    max-width: 34em;
    overflow: hidden;
    margin-left: 0em;
    width: 100%;
    padding-right: 10em;
    transform: scale(0.6);
    */
    overflow: hidden;
    margin-left: 0em;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.imgmosque2{
    max-width: 25em;
}
.tocenter{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.toup{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-width: 22em;
}
.toup2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-width: 22em;
}

.tobottom{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    min-width: 22em;
}

.toright{
    display: flex;
    justify-content: right;
}


.boxparent{
    min-height: 50em;
}
.boxparent0{
    /*min-height: 50em;*/
    min-height: 86em
}

.parent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 6px;
    grid-row-gap: 0px;
    }
    
    .div1 { grid-area: 1 / 1 / 2 / 2; }
    .div2 { grid-area: 1 / 2 / 2 / 3; }
    .div3 { grid-area: 2 / 1 / 3 / 2; }
    .div4 { grid-area: 2 / 2 / 3 / 3; }
/*
.parent {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    }
    
    .div1 { grid-area: 1 / 1 / 2 / 3; }
    .div2 { grid-area: 1 / 3 / 3 / 5; }
    .div3 { grid-area: 2 / 1 / 3 / 2; }
    .div4 { grid-area: 2 / 2 / 3 / 3; }
*/


.parentprops {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 6px;
    grid-row-gap: 0px;
    }
    
    .div1props { grid-area: 1 / 1 / 2 / 2; }
    .div2props { grid-area: 1 / 2 / 2 / 3; }
    .div3props { grid-area: 2 / 1 / 3 / 2; }
    .div4props { grid-area: 2 / 2 / 3 / 3; }
.boxparentpropos{
    /*min-height: 54em;*/
    /*min-height: calc(125em - 20vw);*/
    min-height: calc(163em - 92vw);
}
.tocenteroom{
    display: flex;
    justify-content: center;
    align-items: center;
}
.torightroom{
    display: flex;
    justify-content: center;
    align-items: center;
}

.imgold2{
    /*max-width: 25em;*/
    max-width: 20em;
}
.touproom{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-width: 22em;
}
.tobottomroom{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    min-width: 22em;
}
.boximgold{
    overflow: hidden;
    margin-left: 8vw;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.dialog{
    /*transform: translateY(-8em);*/
    /*transform: translateY(-6.25em);*/
    transform: translateY(-5em);
    height: 3em;
}
.textdialog{
    font-size: 20px;
}
.boxdialog{
    /*top: -5.12em;*/
    top: 0em;
}


.linkgame{
    font-size: 28px;
    margin: 0.3em;}

.boxparchemin{
    transform: rotate(90deg);
    width: 28em;
}
.listlink{
    /*height: 28em;*/
    height: 21em;
}
.actionbox{
    /*
    top: -34em;
    height: 29em;
    height: 0em;
    width: 22em;
    */
    top: -24.5em;
    height: 0em;
    width: 17em;
}

.gamefrange2{
    /*top: -10.2em;*/
    top: -6em;
}

.CircleG{
    /*margin-left: 2vw;*/
    transform: translateY(0vw)translateX(0vw)scale(0.9);
}
.CircleD{
    /*margin-right: 2vw;*/
    transform: translateY(0vw)translateX(0vw)scale(0.9);
    margin-top: 1em;
}
.CircleG:hover{
    transform: translateY(0vw)translateX(0vw)scale(1);
}
.CircleD:hover{
    transform: translateY(0vw)translateX(0vw)scale(1);
}
.colonne3{
    width: 20em;
}
.colonne2{
    z-index: 2;
    margin-left: 2vw;
    margin-right: 2vw;
    width: 18vw;
}
.imgperso{
    transform: translateY(6.58em);
}

.presentation-section {
    padding: 3em;
}
}

@media screen and (min-width: 1300px) {
    .boxparent0{
        min-height: 80em;
    }
}

@media screen and (min-width: 1400px) {

    .margincontact{
        margin: -16em;
    }
    .margincontacttop{
        margin: 4em;
    }

}

@media screen and (min-width: 1600px) {


    .ligne1{

        padding-right: 10vw;
        padding-left: 10vw;
        height: 0em;
    }
    .boximgmosque{
        /*
        max-width: 34em;
        overflow: hidden;
        margin-left: 0em;
        width: 100%;
        padding-right: 10em;
        transform: scale(0.6);
        */
        overflow: hidden;
        margin-left: 0em;
        width: 100%;
    }
    .boximgold{
        overflow: hidden;
        margin-left: 2em;
        width: 100%;
    }
    .imgyoung{

        width: 15vw;
        max-width: 15em;
        max-height: 15em;
    }
    .imgroom{

        width: 15vw;
        max-width: 15em;
        max-height: 15em;
        align-self: flex-start;
        margin-top: 0em;
    }
    .imgmosque2{
        /*max-width: 18em;*/
        max-width: none;
        width: calc(100% - 2em);
        /*padding-left: 2em;*/
        padding-left: 7em;
        
    }
    .imgold2{
        /*max-width: 18em;*/
        /*max-width: none;*/
        max-width: 28vw;
        width: calc(100% - 2em);
        /*padding-left: 2em;*/
        padding-left: 2em;
        
    }

    .tocenter{
        display: block;
        /*min-width: 47vw;*/
        min-width: 40vw;
    }


    .imgplume{
        position: relative;
        left: 2em;
    }

    .toup>p{
        margin-top: 0;
        margin-bottom: 0;
    }
    .toup2>p{
        margin-top: 0;
        margin-bottom: 0;
    }

    .toup{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        min-width: 25em;
    }

    .toup2{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        min-width: 25em;
    }

    .touproom>p{
        margin-top: 0;
        margin-bottom: 0;
    }

    .touproom{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        min-width: 25em;
    }
    .tobottom{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        min-width: 25em;
    }
    .tobottomroom{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        min-width: 25em;
    }
    .toright{
        display: flex;
        justify-content: right;
    }
    .tocenteroom{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .torightroom{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .boxparent{
        min-height: 38em;
    }
    .boxparent0{
        min-height: 65em;
    }
    .boxparentpropos{
        /*min-height: 92em;*/
        min-height: 88em;
    }
    .t40{
        position: relative;
        top: 36em;
    }
    /*
    .parent {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 6px;
        grid-row-gap: 0px;
    }
        
    .div1 { grid-area: 1 / 1 / 2 / 3; }
    .div2 { grid-area: 1 / 3 / 3 / 5; }
    .div3 { grid-area: 2 / 1 / 3 / 2; }
    .div4 { grid-area: 2 / 2 / 3 / 3; }
    */

    .parent {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(5, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        }
        
        .div1 { grid-area: 1 / 1 / 4 / 4; }
        .div2 { grid-area: 1 / 4 / 4 / 7; }
        .div3 { grid-area: 4 / 1 / 6 / 3; }
        .div4 { grid-area: 4 / 3 / 6 / 7; } 

    .parentprops {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 6px;
        grid-row-gap: 0px;
    }
        
    .div1props { grid-area: 1 / 1 / 2 / 3; }
    .div2props { grid-area: 1 / 3 / 3 / 5; }
    .div3props { grid-area: 2 / 1 / 3 / 2; }
    .div4props { grid-area: 2 / 2 / 3 / 3; }




    @-moz-document url-prefix() {
        
        .imgroom{
            min-width: 10em;
            margin-top: 0.3em;
        }
        .tobottom{
            width: 40vw;
        }

        .t40 {
            position: relative;
            top: 36em;
        }
        .boxparent0 {
            min-height: 68em;
        }

        .toup{
            margin-left: -12vw;
            width: 4vw;
        }
        .toup2{
            margin-left: -12vw;
            width: 70vw;
        }
        .toright{
            max-width: 15vw;
        }
        .tocenter{
            margin-left: -12vw;
        }
        .tobottomroom{
            width: 42vw;
        }
        .touproom{
            margin-left: -10vw;
            width: 4vw;
            padding-left: 1em;
        }
        .torightroom{
            max-width: 15vw;
            padding: 0em;
            margin-top: 0em;
        }
        .tocenterroom {
            margin-right: 10vw;
            max-width: 18vw;
          }

    }

}
@media screen and (min-width: 1200px) {

    .lignepp{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 2em;
    }
}