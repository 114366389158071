.imgpartenaire{
    /*box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;*/
    border-radius: 0.5em;
    margin: 1em;
    width: 8em;
}
.partenaires-section{
    width: 45em;
    max-width: 80vw;
}


