.boxlogo{
    /*
    margin-bottom: -4.5em;
    transform: scale(1.3);
    */
    margin-top: 7em;
    /*margin-bottom: 2.5em;*/
    margin-bottom: 3.2em;
    transform: scale(1.3);
    height: 0em;
}

.imglogo{
    width: 10em;
}
.titrelogo{
    font-family: 'Noto Serif', serif;
    text-align: center;
    color: #FEFCFD;
    transform: translateY(-1.6em);
    font-family: 'Cinzel Decorative', cursive;
    letter-spacing: 0.1em;
}
.sublogo{
    color: #83BCFF;
    text-align: center;
}
.cadrelogo{
    position: relative;
    top: 4em;
    margin-bottom: 1em;
}
.btn0{
    background-color: #023360;
    border: none;
    border-radius: 0.5em;
    /*box-shadow: 0 5px 15px rgb(0 0 0 / 35%);*/
    color: #FEFCFD;
    outline: none;
    transform: scale(0.9);
    transition: .3s;
    margin: 1em;
    /*padding: 1em;*/
    padding: 1.8em;
    opacity: 0.8;
}
.btn0:hover {
    background-color: #2c8bdd;
    padding-left: 2em;
    padding-right: 2em;
    transform: scale(1);
    opacity: 1;
    cursor: pointer;
  
}
.imglink{
    width: 2em;
    margin-right: 1em;
}
.cadrelogo2 {
    position: relative;
    top: 1em;
    margin-bottom: 0em;
}
.linkclass{
    text-decoration: none;
}
.game{
    max-width: 45em;
}
@media screen and (min-width: 768px) {
    .ayah{
      font-family: 'Noto Naskh Arabic', serif;
      /* test ici
      font-size: xx-large;
      font-weight: bolder;*/
      font-size: xx-large;
      font-weight: 500;
    }
    .ayahfrench{
      font-family: 'Noto Naskh Arabic', serif;
      font-size:medium;
      /*test ici
      font-weight: bolder;*/
      font-weight: 500;
    }
  }
  @media screen and (min-width: 1024px) {
    .ayah{
      font-family: 'Noto Naskh Arabic', serif;
      /* test ici
      font-size: xx-large;
      font-weight: bolder;*/
      font-size: xx-large;
      font-weight: 500;
    }
    .ayahfrench{
      font-family: 'Noto Naskh Arabic', serif;
      font-size:medium;
      /*test ici
      font-weight: bolder;*/
      font-weight: 500;
    }
    .ayahalone{
      font-size: xxx-large;
    }
  }
  
.titlescore{
    margin-bottom: 0.5em;
}
.inputdefault {
    background-color: #023360;
    border: none;
    border-radius: 0.5em;
    color: #FEFCFD;
    font-size: 1em;
    height: 2em;
    opacity: .8;
    outline: none!important;
    -webkit-transform: scale(.98);
    transform: scale(.9);
    transition: .3s;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  
  }
  .inputdefault:hover{
    transform: scale(1);
  }
  .inputdefault:focus{
    opacity: 1;
  }
  .inp1{
    width: 6em;
  }
  .inp3{
    width: 4em;
  }
  .inp4{
    width: 11em;
    height: 3.5em;
    margin-top: 1em;
  }
