/*@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Cinzel&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Cinzel+Decorative&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Naskh+Arabic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text&family=Merriweather&family=Pirata+One&display=swap');

.exit{
  display: none!important;
}
.cinzel{
  font-family: 'Cinzel', serif;
}
.Merri{
  font-family: 'Merriweather', serif;
}

a{
  text-decoration: none;
}

.ayah{
  font-family: 'Noto Naskh Arabic', serif;
  /* test ici
  font-size: xx-large;
  font-weight: bolder;*/
  font-size: xx-large;
  font-weight: 500;
}
.ayahfrench{
  font-family: 'Noto Naskh Arabic', serif;
  font-size:medium;
  /*test ici
  font-weight: bolder;*/
  font-weight: 500;
}

@media screen and (min-width: 768px) {
  .ayah{
    font-family: 'Noto Naskh Arabic', serif;
    /* test ici
    font-size: xx-large;
    font-weight: bolder;*/
    font-size: xx-large;
    font-weight: 500;
  }
  .ayahfrench{
    font-family: 'Noto Naskh Arabic', serif;
    font-size:medium;
    /*test ici
    font-weight: bolder;*/
    font-weight: 500;
  }
}
@media screen and (min-width: 1024px) {
  .ayah{
    font-family: 'Noto Naskh Arabic', serif;
    /* test ici
    font-size: xx-large;
    font-weight: bolder;*/
    font-size: xx-large;
    font-weight: 500;
  }
  .ayahfrench{
    font-family: 'Noto Naskh Arabic', serif;
    font-size:medium;
    /*test ici
    font-weight: bolder;*/
    font-weight: 500;
  }
}


html {overflow-x:hidden; }

html {
    scrollbar-width: thin;
    scrollbar-color: #2B59C3 transparent;
  }
  
  /* Works on Chrome, Edge, and Safari */
  html::-webkit-scrollbar {
    width: 0.5em;
  }
  
   html::-webkit-scrollbar-track {
    background: transparent;
  }
  
    html::-webkit-scrollbar-thumb {
    background-color: #2B59C3;
    border-radius: 2em;
    /*border: 3px solid transparent;*/
  }
  html::-webkit-scrollbar-corner {
    background: rgba(0,0,0,0);
  }




body{
  /*background-color: #253C78;*/
  background-color: #253C78;
  color: white;
}

.cadrefixe{
  width: 100vw;
  /*min-height:  calc(100vh - 5em);;*/
  min-height: max(calc(100vh - 5em), 48em);
}

/**********************************************************************/

.scrollbar{
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #2B59C3 transparent;
}
.scrollbarx{
  overflow-y: hidden;
}
.scrollbary{
  overflow-x: hidden;
}
  
.scrollbar::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: #2B59C3;
  border-radius: 2em;
  /*border: 3px solid transparent;*/
}
.scrollbar::-webkit-scrollbar-track {
  background: transparent;
}
.scrollbar::-webkit-scrollbar-corner {
  background: rgba(0,0,0,0);
}

/*************************************************/

.boxsurattitle{
  border: solid #2c8bdd;
  border-radius: 0.4em;
  margin: 1em;
  padding: 1.5em;
  padding-top: 0.3em;
  text-align: center;
}

.verse{
  /*margin: 1em;*/
  margin: 0.5em;
  max-width: 85vw;
}

.margin0{
  margin: 0em;
}
.margin05{
  margin: 0.5em;
}
.margin1{
  margin: 1em;
}
.errorgame{
  margin: 1em;
}

.arrowreturn{
  transform: rotate(-90deg);
  width: 0.8em;
  margin-right: 0.8em;
}
.arrowreturn2{
  transform: rotate(90deg);
  width: 0.8em;
  margin-left: 0.8em;
}
.returngame{
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-color: #023360;
  color: white;
  padding: 1.5em;
  margin: 1em;
  transition: 0.3s;
  border-radius: 0.5em;
  border: none;
  font-size: 0.7em;
}
.returngame:hover{
  background-color:#2c8bdd;
}
.returngame2{
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-color: #972020;
  color: white;
  padding: 1.5em;
  margin-bottom: 1em;
  transition: 0.3s;
  border-radius: 0.5em;
  border: none;
  font-size: 0.7em;
}
.returngame2:hover{
  background-color:#ce4040;
}

.cadreverse{
  margin: 2em;
  max-width: 50em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.cadreafterfixe{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

/************************************************/

.modalscreen{
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.82;
  position: fixed;
  top: 0em;
  z-index: 10;
}
.modalcenter{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0em;
  z-index: 11;

}

.btn{
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.pointer:hover{
  cursor: pointer;
}

.transi3{
  transition: 0.3s;
}
.textcenter{
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.talign{
  text-align: center;
}
.talign-end{
  text-align: end;
}
.talign-start{
  text-align:start;
}
.talign-justify{
  text-align:justify;
}
.centerco{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.centercostart{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.centerstart{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.bluefond{
  background-color: #253C78;
}
.nw{
  white-space: nowrap;
}

.a768{
  display: none !important;
}
.a768f{
  display: none !important;
}

.bigger{
  transform: scale(0.95);
  transition: 0.3s;
}
.bigger:hover{
  transform: scale(1);
}

@media screen and (min-width: 768px) {
  .a768{
      display:block !important;
  }
  .a768f{
      display: flex !important;
  }
  .d768{
      display: none !important;
  }
}


.a1024{
  display: none !important;
}
.a1024f{
  display: none !important;
}

@media screen and (min-width: 1024px) {
  .a1024{
      display: block !important;;
  }
  .a1024f{
      display: flex!important;
  }
  .d1024{
      display: none !important;
  }

}






@keyframes modalscin {
  
  0% {
    transform: translateY(1em);
    opacity:0;
  }
  80% {
    transform: translateY(0em);
    opacity:0.656;
  }
  100% {
    opacity:0.82;
    transform: translateY(0em);
  }
}
@keyframes modalscout {
  
  from {
    transform: translateY(0em);
    opacity:0.82;
  }
  to {
    opacity:0;
    transform: translateY(1em);
  }
}
@keyframes modalbcin {
  
  0% {
    opacity:0;
  }
  30% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
@keyframes modalbcout {
  
  from {
    opacity:1;
  }
  to {
    opacity:0;
  }
}
@keyframes fadein {
  
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}
@keyframes fadeout {
  
  from {
    opacity:1;
  }
  to {
    opacity:0;
  }
}
@keyframes biggersmall {
  
  0% {
    transform:scale(0.9);
  }
  50% {
    transform:scale(1.1);
  }
  100% {
    transform:scale(0.9);
  }
}

@keyframes turnappear {
  
  from {
    transform: rotateX(180deg);
    opacity:0;
  }
  to {
    opacity:1;
    transform: rotateX(0deg);
  }
}
@keyframes turnappear2 {
  
  from {
    transform: rotateX(0deg);
    opacity:0;
  }
  to {
    opacity:1;
    transform: rotateX(180deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .apparition {
    animation: fadein 0.5s ease-in-out;
  }
  .apparitionq {
    animation: fadein 0.3s ease-in-out;
  }
  .disparition {
    animation: fadeout 0.5s ease-in-out;
  }
  .bandg {
    animation: biggersmall 0.5s ease-in-out infinite;
  }
  .modalsenter {
    animation: modalscin 0.5s ease-in-out;
  }
  .modalsleave {
    animation: modalscout 0.5s ease-in-out;
  }
  .modalbenter {
    animation: modalbcin 0.6s  ease-in-out;
  }
  .modalbleave {
    animation: modalbcout 0.6s  ease-in-out;
  }
  .tournappear {
    animation: turnappear 0.5s ease-in-out;
  }
  .tournappear2 {
    animation: turnappear2 0.5s ease-in-out;
  }
}
