.footer{
    width: 100vw;
    height: 18em;
    background-color: #023360;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    font-family: 'Candara', sans-serif;
}
.divfoot{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
}
.div2foot{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content:space-between;
}
.div3foot{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content:center;
}
.boxlogofoot{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.navfoot{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
}
.linkfoot{
    text-decoration: none;
    font-size: 0.6em;
    color: white;
    margin: 0.5em;
    transition: 0.3s;
}
.linkfoot:hover{
    color: #2c8bdd;
}
.titlenav{
    margin:0.2em;
    font-size: 0.7em;
}
.hr2foot{
    color: white;
    background-color: white;
    height: 0.1em;

}
.logofoot{
    width: 4em;
    transform: scale(0.85);
    transition: 0.3s;
}
.logofoot:hover{
    transform: scale(1);
}
.cls-6{
    transition: 0.3s;
}
.account2{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.cls-7{
    transition: 0.3s;
}
.cls-face{
    transition: 0.3s;
}
.logoface{
    width: 2em;
    transform: scale(0.85);
    transition: 0.3s;
}
.logoface:hover{
    transform: scale(1);
}
.cls-you{
    transition: 0.3s;
}
.logoyou{
    width: 2em;
    transform: scale(0.85);
    transition: 0.3s;
}
.logoyou:hover{
    transform: scale(1);
}
.cls-twitt{
    transition: 0.3s;
}
.logotwitt{
    width: 2em;
    transform: scale(0.85);
    transition: 0.3s;
}
.logotwitt:hover{
    transform: scale(1);
}
.cls-insta1{
    transition: 0.3s;
}
.cls-insta2{
    transition: 0.3s;
}
.logoinsta{
    width: 2em;
    transform: scale(0.85);
    transition: 0.3s;
}
.logoinsta:hover{
    transform: scale(1);
}
.cls-send1{
    transition: 0.3s;
}
.cls-send2{
    transition: 0.3s;
}
.logosend{
    width: 2em;
    transform: scale(0.85);
    transition: 0.3s;
}
.logosend:hover{
    transform: scale(1);
}
.barrehr{
    color: white;
    background-color: white;
    height: 0.3em;
    width: 20vw;
}
.entrebarre{
    width: 60vw;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.iconaccount2{
    width: 1.2em;
    margin-right: 0.5em;
}
.divnav{
    margin:0.5em;
}
.copywright{
    font-size: 0.7em;
}

@media screen and (min-width: 768px) {
    .barrehr{
        height: 0.3em;
        width: 30vw;
    }
    .entrebarre{
        width: 40vw;

    }
    .logoface{
        width: 2.5em;
    }
    .logotwitt{
        width: 2.5em;
    }
    .logoinsta{
        width: 2.5em;
    }
    .logosend{
        width: 2.5em;
    }
    .logoyou{
        width: 2.5em;
    }
    .titlenav{
        margin: 0.4em;
        font-size: 1em;
    }
    .linkfoot{
        font-size: 0.9em;
    }

    .copywright{
        font-size: 1em;
    }
    .footer{
        height: 26em;
    }
    .divfoot{
        margin-top: 2em;
    }
    .div2foot{
        margin-top: 3em;
        margin-bottom: 0.5em;
    }
}