
.loader-container {
    position: fixed;
    /*top: 0;*/
    top: 50%;
    /*left: 0;*/
    width: 100%;
    /*height: 100%;*/
    /*background-color: rgba(255, 255, 255, 0.7);*/
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Assurez-vous que le loader est bien au-dessus des autres éléments */
  }
  
  .loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #3498db;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }