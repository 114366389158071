/* styles.css ou un autre fichier CSS */
.pub-container {
    width: 160px;  /* Largeur du Skyscraper large */
    height: 600px; /* Hauteur du Skyscraper large */
    overflow: hidden; /* Rogne l'image si elle déborde */
    cursor: pointer; /* Change le curseur en main pour indiquer que c'est cliquable */
    position: relative;
    border-radius: 0.5em; /* Pour la mise en page */
  }
  .pub-container2 {
    width: 300px;  /* Largeur du Skyscraper large */
    height: 1050px; /* Hauteur du Skyscraper large */
    overflow: hidden; /* Rogne l'image si elle déborde */
    cursor: pointer; /* Change le curseur en main pour indiquer que c'est cliquable */
    position: relative;
    border-radius: 0.5em; /* Pour la mise en page */
  }
  .pub-container3 {
    width: 160px;  /* Largeur du Skyscraper large */
    height: 600px; /* Hauteur du Skyscraper large */
    overflow: hidden; /* Rogne l'image si elle déborde */
    cursor: pointer; /* Change le curseur en main pour indiquer que c'est cliquable */
    position: relative;
    border-radius: 0.5em; /* Pour la mise en page */
  }
  .pub-container4 {
    width: 240px;  /* Largeur du Skyscraper large */
    height: 400px; /* Hauteur du Skyscraper large */
    overflow: hidden; /* Rogne l'image si elle déborde */
    cursor: pointer; /* Change le curseur en main pour indiquer que c'est cliquable */
    position: relative;
    border-radius: 0.5em; /* Pour la mise en page */
  }
  .pub-image {
    width: 100%; /* Utilisez la largeur complète du conteneur */
    height: auto; /* Conserve le rapport hauteur/largeur de l'image */
    position: absolute; /* Pour le centrage vertical */
    top: 50%; /* Pour le centrage vertical */
    transform: translateY(-50%); /* Pour le centrage vertical */
    transition: 0.3s;
  }

  .pub-image:hover{
    transform: translateY(-50%)scale(1.2);
  }

  /*
  .pub-horizontal-container {
    width: 728px;  
    height: 90px;  
    overflow: hidden;
    cursor: pointer;
    position: relative;
    margin-bottom: 0.5em;
  }*/
  
  .pub-horizontal-container>a {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
  }
  .pub-horizontal-image {
    position: absolute;
    top: 50%;
    transition: 0.3s;
    /*min-width: 100%;*/
    min-height: 90px;
    height: 90px;
    max-height: 90px;
    transform: translateY(-50%);
    border-radius: 0.5em;
  }
  .pub-horizontal-image:hover{
    transform: translateY(-50%)scale(1.2);
  }

  .pub-horizontal-image:hover{
    transform: translateY(-25%)translateY(-50%)scale(1.2);
  }

  .pub-horizontal-container {
    width: 234px;  /* Largeur du Leaderboard */
    height: 60px;  /* Hauteur du Leaderboard */
    overflow: hidden;
    cursor: pointer;
    position: relative;
    margin-bottom: 1em;
  }

  @media screen and (min-width: 420px) {
    .pub-horizontal-container {
        width: 468px;  /* Largeur du Leaderboard */
        height: 60px;  /* Hauteur du Leaderboard */
        overflow: hidden;
        cursor: pointer;
        position: relative;
        max-width: 78vw;
        max-height: 10vw;
        margin-bottom: 0.5em;
      }
      .pub-horizontal-container>a {
        width: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
      }
      .pub-horizontal-image {
        position: absolute;
        top: 50%;
        transition: 0.3s;
        /*min-width: 100%;*/
        min-height: 60px;
        height: 60px;
        max-height: 60px;
        transform: translateY(-50%);
        border-radius: 0.5em;
      }

  }

  @media screen and (min-width: 768px) {
    .pub-horizontal-container {
        width: 728px;  /* Largeur du Leaderboard */
        height: 90px;  /* Hauteur du Leaderboard */
        overflow: hidden;
        cursor: pointer;
        position: relative;
        max-width: 78vw;
        max-height: 10vw;
        margin-bottom: 0.5em;
      }
      .pub-horizontal-container>a {
        width: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
      }
      .pub-horizontal-image {
        position: absolute;
        top: 50%;
        transition: 0.3s;
        /*min-width: 100%;*/
        min-height: 90px;
        height: 90px;
        max-height: 90px;
        transform: translateY(-50%);
        border-radius: 0.5em;
      }
      .pub-horizontal-image:hover{
        transform: translateY(-50%)scale(1.2);
      }
  }