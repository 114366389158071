@keyframes fadein {
  
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}
@keyframes fadeout {
  
  from {
    opacity:1;
  }
  to {
    opacity:0;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .apparition {
    animation: fadein 0.5s ease-in-out;
  }
  .disparition {
    animation: fadeout 0.5s ease-in-out;
  }
}

#seek-bar{
    margin: 1em;
    width: 14em;
    height: 0.3em;
    background-color:#FEFCFD;
    display: flex;
    border-radius: 3em;
    margin-left: 1.5em;
    cursor: pointer;
}
#fill{
  height: 0.3em;
  background-color:#2B59C3;
  border-radius: 1.5em;
}
#handle{
  width: 0.3em;
  height: 0.3em;
  background-color:#023360;
  /*border-radius: 50%;*/
  /**********/
  margin-left: -0.1em;
  transform: scale(2);
}
.seek-bar{
  margin: 1em;
  width: 14em;
  height: 0.3em;
  background-color:#FEFCFD;
  display: flex;
  border-radius: 3em;
  margin-left: 1.5em;
  cursor: pointer;
}
.seek-bar0{
  margin: 1em;
  height: 0.3em;
  background-color:#FEFCFD;
  display: flex;
  border-radius: 3em;
  margin-left: 1.5em;
  cursor: pointer;
}
.fill{
height: 0.3em;
background-color:#2B59C3;
border-radius: 1.5em;
}
.handle{
width: 0.3em;
height: 0.3em;
background-color:#023360;
/*border-radius: 50%;*/
/**********/
margin-left: -0.1em;
transform: scale(2);
}

.slidersa3[type='range'] {
    overflow: hidden;
    width: 4em;
    border-radius: 1em;
    -webkit-appearance: none;
    background-color: #FEFCFD;
  }
  
  .slidersa3[type='range']::-webkit-slider-runnable-track {
    height: 10px;
    width: 14em;
    -webkit-appearance: none;
    color: #13bba4;
    margin-top: -1px;
  }
  
  .slidersa3[type='range']::-webkit-slider-thumb {
    width: 0.5em;
    -webkit-appearance: none;
    height: 4em;
    cursor:pointer;
    background: white;
    box-shadow: -20em -5em 5em 20em #2c8bdd;
  }
  
  .slidersa3[type="range"]::-moz-range-progress {
    background-color: #2c8bdd; 
  }
  .slidersa3[type="range"]::-moz-range-track {  
    background-color: #FEFCFD;
  }
  /* IE*/
  .slidersa3[type="range"]::-ms-fill-lower {
    background-color: #2c8bdd; 
  }
  .slidersa3[type="range"]::-ms-fill-upper {  
    background-color: #FEFCFD;
  }

  .pointer{
    cursor: pointer;
  }

  .btn1{
    background-color: #023360;
    border: none;
    border-radius: 0.5em;
    /*box-shadow: 0 5px 15px rgb(0 0 0 / 35%);*/
    color: #FEFCFD;
    outline: none;
    /*transform: scale(0.9);*/
    transition: .3s;
    margin: 0.5em;
    padding: 0.5em;
    opacity: 0.8;
    
  }
  .btn1:hover {
    background-color: #2c8bdd;
    /*transform: scale(1);*/
    opacity: 1;
    cursor: pointer;
  
  }

  
  .btn2{
    background-color: #023360;
    border: none;
    border-radius: 0.5em;
    /*box-shadow: 0 5px 15px rgb(0 0 0 / 35%);*/
    color: #FEFCFD;
    outline: none;
    /*transform: scale(0.9);*/
    transition: .3s;
    margin: 0.5em;
    opacity: 0.8;
    
  }
  .btn2:hover {
    background-color: #2c8bdd;
    /*transform: scale(1);*/
    opacity: 1;
    cursor: pointer;
  
  }

.btn3 {
  background-color: #023360;
  border: none;
  border-radius: 0.5em;
  /*box-shadow: 0 5px 15px rgb(0 0 0 / 35%);*/
  color: #FEFCFD;
  outline: none;
  /*transform: scale(0.9);*/
  transition: .3s;
  margin: 1em;
  padding: 1.2em;
  opacity: 0.8;
}
.btn3 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.btn3:hover {
  background-color: #2c8bdd;
  /*transform: scale(1);*/
  padding-right: 1.5em;
  padding-left: 1.5em;
  opacity: 1;
  cursor: pointer;
}


  .imgbutton{
    max-height: 1.9em;
  }
  .transitiona{
    transition: 0.3s;
  }

.audiovolume{
  height:2.1em;
  width:2.1em;
  border-radius:0.5em;
  margin: 0.5em;
  padding: 0.25em;
  display:flex;
  flex-direction:column;
  align-items:center;
  overflow:hidden;
  background-color: #023360;
  opacity: 0.8;
}
.audiovolume:hover {
  background-color: #2c8bdd;
}

.testvolumecont{
  /*
  height: 3em;
  position: relative;
  top: -0.48em;
  margin: 0.4em;
  */

  height: 1em;
  position: relative;
  top: 1.25em;

}

.allbuttons{
  /*margin-bottom: 3em;*/
  margin-top: -1em;
  margin-bottom: -0.5em;
}

.rot180{
  transform: rotate(180deg);
}

.imgspeaker{
  width: 1.1em;
  top: 0.5em;
  position: relative;
}

.audioinplay>.allbuttons>.testvolumecont>.audiovolume{
  height: 1.6em;
  width: 1.6em;
  margin: 0.25em;
}
.audioinplay>.allbuttons>.testvolumecont{
  top: 0.75em!important;
}

.audioinplay>.allbuttons>.testvolumecont>.audiovolume>.imgbutton{
  top: 0.25em!important;
}