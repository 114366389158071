@keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}
@media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 1s linear;
    }
    .App-logo-reverse {
        animation: App-logo-spin infinite reverse 1s linear;
      }
}

.exit{
    display: none !important;
}

.timerscreen{
    width: 100vw;
    height: 5em;
    text-align: center;
}

.centrer{
    display: flex;
    align-items: center;
    justify-content: center;
}
.centrerco{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.smallscreen{
    background-color: blue;
    width: 10em;
    height: 10em;
    border-radius: 50%;
    overflow: hidden;
    margin: 1em;
}
.firstcircle{
    /*background-color: blue;*/
    width: 6em;
    height: 6em;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    top: 2.5em;
}
.chrono{
    margin: 1em;
}

.barre{
    background-color: yellow;
    width: 10em;
    height: 2em;
    position: relative;
    left: 0em;
    
}
.barre2{
    background-color: #19609e;
    width: 5em;
    height: 2em;
    position: relative;
    left: 2.5em;
   /* transform-origin: 0% 0% */
   /* transform-origin: 50px 50px */
   transform-origin: left center;

    
}

.circle{
    background-color: green;
    width: 9.5em;
    height: 9.5em;
    border-radius: 50%;
    position: relative;
    left: -0.5em;
}
.circle2{
    /*background-color: green;*/
    width: 9.5em;
    height: 9.5em;
    border-radius: 50%;
    position: relative;
    left: 0em;
}

.circle3{
    background-color: #063a67;
    width: 5em;
    height: 5em;
    border-radius: 50%;
    position: relative;
    top: -3em;

}
.circle4{
    width: 5em;
    height: 5em;
    border-radius: 50%;
    position: relative;
    top: -3em;

}
.barre3{
    /*background-color: yellow;*/
    background-color:#063a67;
    width: 1em;
    height: 1.4em;
    position: relative;
    left: 3em;
    transform-origin: -2.5em 0.7em;
    
}
.blue{
    color: #063a67;
}
.white{
    color: white;
}

.number{
    font-size: xx-large;
    font-weight: bolder;
}
