.toggleSwitch-container {
    width: 60px;
    height: 34px;
    display: inline-block;
    margin: 0.2em;
}

.toggleSwitch {
    position: relative;
    width: 100%;
    height: 100%;
    display: inline-block;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #031a30;
    border-radius: 2em;
}

.toggleSwitch input {
    display: none;
}

.toggleSwitch-round {
    position: absolute;
    /*top: 0;*/
    top: 2px;
    /*left: 0;*/
    left: 2px;
    width: 26px;
    height: 26px;
    background-color: #ccc;
    border: 2px solid #555;
    border-radius: 50%;
    transition: transform 0.3s;
}

.toggleSwitch input:checked + .toggleSwitch-round {
    transform: translateX(26px);
    background-color: #0f4b83;
}